

// ** MUI Imports
// import { Theme } from '@mui/material/styles';

const MuiPaper = () => {
    return {
        MuiPaper: {
            styleOverrides: {
                root: {
                    // backgroundImage: 'red'
                }
            }
        }
    };
};

export default MuiPaper;
